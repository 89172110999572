// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "@keyframes Modal_fadeOut__2XxeI {\n  0% {\n    transform: translateX(-50%) scale(0.8);\n  }\n  100% {\n    transform: translateX(-50%) scale(1);\n  }\n}\n.Modal_container__1-pt- {\n  animation: Modal_fadeOut__2XxeI 0.5s ease;\n  position: absolute;\n  top: 5%;\n  left: 50%;\n  transform: translateX(-50%) scale(1);\n}", ""]);
// Exports
exports.locals = {
	"container": "Modal_container__1-pt-",
	"fadeOut": "Modal_fadeOut__2XxeI"
};
module.exports = exports;
