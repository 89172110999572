// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".modal_container__2L3fj {\n  position: fixed;\n  z-index: 1000;\n  width: 100%;\n  height: 100vh;\n  top: 0rem;\n  left: 0rem;\n}\n\n.modal_overlayToggleContainer__1Xny4 {\n  margin-bottom: 1.5rem;\n  width: 2.5rem;\n  height: 2.5rem;\n  border-radius: 50%;\n  position: absolute;\n  right: 2rem;\n  top: 1rem;\n  padding: 0.5rem;\n  background-color: #fff;\n  cursor: pointer;\n  z-index: 1000;\n}\n\n.modal_overlayToggle__3kHUq {\n  width: 100%;\n  height: 100%;\n}", ""]);
// Exports
exports.locals = {
	"container": "modal_container__2L3fj",
	"overlayToggleContainer": "modal_overlayToggleContainer__1Xny4",
	"overlayToggle": "modal_overlayToggle__3kHUq"
};
module.exports = exports;
