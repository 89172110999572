// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".Form_formContainer__2n1mG {\n  width: 42rem;\n  height: max-content;\n  padding: 3rem 3rem;\n  background: #fff;\n  border-radius: 0.5rem;\n}\n@media screen and (max-width: 400px) {\n  .Form_formContainer__2n1mG {\n    width: 35rem;\n  }\n}\n\n.Form_logo__1ofQI {\n  display: block;\n  width: 11rem;\n  height: 4rem;\n  margin: 0 auto;\n  margin-bottom: 2.5rem;\n}\n\n.Form_title__125sv {\n  font-size: 1.8rem;\n  font-weight: 700;\n}\n\n.Form_large__3zkPF {\n  font-size: 2rem;\n}\n\n.Form_default__3Y9PW {\n  font-size: 1.7rem;\n}\n\n.Form_caption__APMLK {\n  font-size: 1.3rem;\n  margin-top: 1.5rem;\n  margin-bottom: 3rem;\n}\n\n.Form_form__1Ugqe {\n  display: grid;\n  grid-template-columns: repeat(1, 1fr);\n  grid-gap: 3rem;\n  gap: 3rem;\n}", ""]);
// Exports
exports.locals = {
	"formContainer": "Form_formContainer__2n1mG",
	"logo": "Form_logo__1ofQI",
	"title": "Form_title__125sv",
	"large": "Form_large__3zkPF",
	"default": "Form_default__3Y9PW",
	"caption": "Form_caption__APMLK",
	"form": "Form_form__1Ugqe"
};
module.exports = exports;
