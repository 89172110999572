// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".FundWalletSummary_container__3GBoV {\n  width: max-content; /* Firefox/Gecko */\n  width: -webkit-max-content; /* Chrome */\n  height: max-content;\n  background-color: #fff;\n  border-radius: 1rem;\n  padding: 4rem 3rem;\n  font-size: 1.4rem;\n}\n@media (max-width: 37.5em) {\n  .FundWalletSummary_container__3GBoV {\n    width: 95%;\n  }\n}\n@media (max-width: 20.62rem) {\n  .FundWalletSummary_container__3GBoV {\n    width: 99%;\n  }\n}\n\n.FundWalletSummary_back__3BtiO {\n  width: max-content;\n  padding: 1rem;\n  position: absolute;\n  top: 2rem;\n  left: 1rem;\n  display: flex;\n  align-items: center;\n  cursor: pointer;\n}\n\n.FundWalletSummary_backIcon__3DsES {\n  width: 2rem;\n  margin-right: 0.7rem;\n}\n\n.FundWalletSummary_backText__26gbT {\n  font-size: 1.4rem;\n}\n\n.FundWalletSummary_logoContainer__1oa4g {\n  width: 11rem;\n  height: 5rem;\n  margin: 2rem auto;\n}\n\n.FundWalletSummary_logo__C7y_s {\n  width: 100%;\n  height: 100%;\n}\n\n.FundWalletSummary_heading__1w-a9 {\n  min-height: 4rem;\n  width: 100%;\n  display: flex;\n  align-items: center;\n  background-color: rgba(193, 207, 226, 0.534);\n  padding: 1rem;\n  border-radius: 0.5rem;\n  color: #2666ff;\n  margin-bottom: 3rem;\n}\n\n.FundWalletSummary_headingIconContainer__2zOrK {\n  width: 2.5rem;\n  height: 2.5rem;\n  margin-right: 1.5rem;\n  padding: 0.5rem;\n  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.2);\n  background-color: #fff;\n  border-radius: 50%;\n}\n\n.FundWalletSummary_headingIcon__1cxWD {\n  width: 100%;\n  height: 100%;\n}\n\n.FundWalletSummary_content__31W5D {\n  margin-bottom: 3rem;\n}\n\n.FundWalletSummary_contentItem__12k3l {\n  display: flex;\n  justify-content: space-between;\n  align-items: center;\n}\n.FundWalletSummary_contentItem__12k3l:not(:last-child) {\n  margin-bottom: 3rem;\n}\n\n.FundWalletSummary_total__1kfpC {\n  font-size: 1.6rem;\n  font-weight: 700;\n}", ""]);
// Exports
exports.locals = {
	"container": "FundWalletSummary_container__3GBoV",
	"back": "FundWalletSummary_back__3BtiO",
	"backIcon": "FundWalletSummary_backIcon__3DsES",
	"backText": "FundWalletSummary_backText__26gbT",
	"logoContainer": "FundWalletSummary_logoContainer__1oa4g",
	"logo": "FundWalletSummary_logo__C7y_s",
	"heading": "FundWalletSummary_heading__1w-a9",
	"headingIconContainer": "FundWalletSummary_headingIconContainer__2zOrK",
	"headingIcon": "FundWalletSummary_headingIcon__1cxWD",
	"content": "FundWalletSummary_content__31W5D",
	"contentItem": "FundWalletSummary_contentItem__12k3l",
	"total": "FundWalletSummary_total__1kfpC"
};
module.exports = exports;
