// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".Balance_balance__2CBVY {\n  height: 15rem;\n  display: flex;\n  align-items: center;\n  background-color: #E4AA3D;\n  background-size: 7rem;\n  padding: 0 5rem;\n  color: #ffffff;\n  font-family: \"Poppins\", monospace, arial, helvetica, sans-serif;\n  border-radius: 1rem;\n  justify-content: space-between;\n}\n.Balance_balance__2CBVY .Balance_balanceWrapper__3K94E {\n  display: flex;\n}\n.Balance_balance__2CBVY .Balance_balances__1QoW0 {\n  margin-right: 10rem;\n}\n@media print {\n  .Balance_balance__2CBVY {\n    display: none;\n  }\n}\n@media (max-width: 56.25em) {\n  .Balance_balance__2CBVY {\n    padding: 0 3rem;\n    background-position: bottom 0px right 5%;\n  }\n}\n@media (max-width: 450px) {\n  .Balance_balance__2CBVY {\n    padding: 0 2rem;\n    background-position: bottom 0px right 4%;\n    background-size: 6.5rem;\n  }\n}\n\n.Balance_balanceHeading__33XHg {\n  font-weight: 500;\n  font-size: 1.5rem;\n  margin-bottom: 1rem;\n}\n\n.Balance_balanceText__2jcGu {\n  font-weight: 800;\n  font-size: 3.5rem;\n}\n@media (max-width: 25rem) {\n  .Balance_balanceText__2jcGu {\n    font-size: 3.2rem;\n  }\n}\n@media (max-width: 20.62rem) {\n  .Balance_balanceText__2jcGu {\n    font-size: 2.5rem;\n  }\n}\n\n.Balance_refresh__2vHQu {\n  width: 1.5rem;\n  margin-left: 1rem;\n  transition: 1s all;\n  cursor: pointer;\n}\n.Balance_refresh__2vHQu:active {\n  animation: Balance_rotate__mnbBz 0.5s ease-in;\n}\n\n.Balance_agentCategoryImage__An9CZ {\n  width: 8rem;\n  height: 8rem;\n  display: block;\n  margin: 0 auto;\n}\n\n.Balance_agentCategoryText__2JtOL {\n  font-size: 18px;\n  text-align: center;\n  color: #ffffff;\n}\n@media (max-width: 20.62rem) {\n  .Balance_agentCategoryText__2JtOL {\n    font-size: 1.5rem;\n  }\n}\n@keyframes Balance_rotate__mnbBz {\n  0% {\n    transform: rotate(0deg);\n  }\n  50% {\n    transform: rotate(180deg);\n  }\n  100% {\n    transform: rotate(360deg);\n  }\n}", ""]);
// Exports
exports.locals = {
	"balance": "Balance_balance__2CBVY",
	"balanceWrapper": "Balance_balanceWrapper__3K94E",
	"balances": "Balance_balances__1QoW0",
	"balanceHeading": "Balance_balanceHeading__33XHg",
	"balanceText": "Balance_balanceText__2jcGu",
	"refresh": "Balance_refresh__2vHQu",
	"rotate": "Balance_rotate__mnbBz",
	"agentCategoryImage": "Balance_agentCategoryImage__An9CZ",
	"agentCategoryText": "Balance_agentCategoryText__2JtOL"
};
module.exports = exports;
