// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".CustomerStatus_container__1pLrF {\n  width: 90rem;\n  height: 50rem;\n  display: flex;\n  align-items: center;\n  justify-content: space-around;\n  background: #fff;\n}\n@media screen and (max-width: 900px) {\n  .CustomerStatus_container__1pLrF {\n    width: 80rem;\n  }\n}\n@media screen and (max-width: 700px) {\n  .CustomerStatus_container__1pLrF {\n    width: 65rem;\n  }\n}\n\n.CustomerStatus_section__2ABwy {\n  flex: 0 0 27%;\n  height: 70%;\n  padding: 1.5rem;\n}\n\n.CustomerStatus_sectionStatus__fCcQU {\n  text-align: center;\n  padding: 2rem 0.5rem;\n}\n\n.CustomerStatus_sectionImageContainer__2k9tB {\n  width: 6rem;\n  height: 6rem;\n  margin: 0 auto;\n}\n\n.CustomerStatus_sectionImage__2fZGQ {\n  width: 100%;\n  height: 100%;\n}\n\n.CustomerStatus_sectionTag__3GX0K {\n  font-size: 1.8rem;\n  text-align: center;\n  letter-spacing: 0.02em;\n  color: #22215b;\n  margin-bottom: 2rem;\n  margin-top: 1rem;\n}\n\n.CustomerStatus_sectionStatusCheckContainer__AIRV- {\n  width: 3rem;\n  height: 3rem;\n  margin: 0 auto;\n  margin-bottom: 1.5rem;\n}\n\n.CustomerStatus_sectionStatusCheck__U5Bra {\n  width: 100%;\n  height: 100%;\n}\n\n.CustomerStatus_sectionStatusIndicator__Ri_ai {\n  width: 100%;\n  height: 0.8rem;\n  display: inline-block;\n  border-radius: 3px;\n  margin: 0 auto;\n  background: #00ea25;\n}\n\n.CustomerStatus_sectionDetails__2ik3o {\n  font-size: 15px;\n  text-align: center;\n  letter-spacing: 0.02em;\n  color: #22215b;\n  line-height: 2.4rem;\n  padding: 0.5rem;\n}", ""]);
// Exports
exports.locals = {
	"container": "CustomerStatus_container__1pLrF",
	"section": "CustomerStatus_section__2ABwy",
	"sectionStatus": "CustomerStatus_sectionStatus__fCcQU",
	"sectionImageContainer": "CustomerStatus_sectionImageContainer__2k9tB",
	"sectionImage": "CustomerStatus_sectionImage__2fZGQ",
	"sectionTag": "CustomerStatus_sectionTag__3GX0K",
	"sectionStatusCheckContainer": "CustomerStatus_sectionStatusCheckContainer__AIRV-",
	"sectionStatusCheck": "CustomerStatus_sectionStatusCheck__U5Bra",
	"sectionStatusIndicator": "CustomerStatus_sectionStatusIndicator__Ri_ai",
	"sectionDetails": "CustomerStatus_sectionDetails__2ik3o"
};
module.exports = exports;
