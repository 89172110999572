// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".createAgent_register__3wzNF {\n  min-height: 100vh;\n  color: #000000;\n  font-size: 1.4rem;\n  background-color: #fff;\n}\n\n.createAgent_createAgent__XdQBi {\n  width: 100%;\n  min-height: calc(100vh - 8rem);\n  padding: 3.5rem 0;\n}\n\n.createAgent_create__10Bag {\n  width: 65rem;\n  margin: 0 auto;\n  text-align: center;\n}\n@media (max-width: 673px) {\n  .createAgent_create__10Bag {\n    width: 95%;\n  }\n}\n\n.createAgent_steps__h3KvM {\n  width: 50%;\n  width: 35rem;\n  margin: 0 auto;\n  margin-bottom: 1rem;\n  display: flex;\n  justify-content: space-between;\n  border-bottom: 1px solid #f5f5f5;\n}\n@media (max-width: 550px) {\n  .createAgent_steps__h3KvM {\n    width: 70%;\n  }\n}\n@media (max-width: 370px) {\n  .createAgent_steps__h3KvM {\n    width: 80%;\n  }\n}\n\n.createAgent_tab__fKIvT {\n  height: 100%;\n  font-size: 1.7rem;\n  color: #c4c4c4;\n  padding-bottom: 0.8rem;\n}\n\n.createAgent_tabActive__3ihCf {\n  color: #123c68;\n  font-weight: 600;\n  border-bottom: 2px solid #123c68;\n}", ""]);
// Exports
exports.locals = {
	"register": "createAgent_register__3wzNF",
	"createAgent": "createAgent_createAgent__XdQBi",
	"create": "createAgent_create__10Bag",
	"steps": "createAgent_steps__h3KvM",
	"tab": "createAgent_tab__fKIvT",
	"tabActive": "createAgent_tabActive__3ihCf"
};
module.exports = exports;
