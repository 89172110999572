import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import styles from './index.module.scss';
import axios from 'axios';

import { setCurrentPage } from '../../actions/page';
import { setDisplayModal } from '../../actions/modal';
import { useToasts } from 'react-toast-notifications';

import userGroup from '../../assets/icons/users.svg';

import ListTerminals from './ListTerminals';
import { LIST_AGENT_TERMINAL, UNASSIGN_TERMINAL } from '../../utils/constants';

export const Terminals = ({ changeCurrentPage, displayModal, modalIsUpdated }) => {
    const [isUpdated, setIsUpdated] = useState(false);
    const [terminals, setTerminals] = useState([]);
    const [loading, setLoading] = useState(false);

    const { addToast } = useToasts();

    useEffect(() => {
        let isCancelled;

        (async function listTerminals() {
            setLoading(true);

            try {
                const res = await axios.get(LIST_AGENT_TERMINAL);

                if (!isCancelled) {
                    setTerminals(res?.data?.data);
                }
            } catch (e) {
                // console.log(e);
            } finally {
                setLoading(false);
            }
        })();

        return () => {
            isCancelled = true;
        };
    }, [modalIsUpdated]);

    const handleDeactivateTerminal = (terminal_id, user_id) => {
        const getConfirmation = window.confirm(
            'Do you want to deactivate terminal?'
        );

        if (getConfirmation) {
            (async function unassignTerminal() {
                try {
                    const res = await axios.post(`${UNASSIGN_TERMINAL}/${terminal_id}/users/${user_id}/unassign`, []);

                    if (res) {
                        addToast('Terminal deactivated successfully', {
                            appearance: 'success',
                            autoDismiss: true,
                        });

                        (async function listTerminals() {
                            setLoading(true);

                            try {
                                const res = await axios.get(LIST_AGENT_TERMINAL);
                                setTerminals(res?.data?.data);
                            } catch (e) {
                            } finally {
                                setLoading(false);
                            }
                        })();
                    }
                } catch (e) {
                    addToast('Terminal not deactivated', {
                        appearance: 'error',
                        autoDismiss: true,
                    });
                } finally {
                    setIsUpdated(terminal_id);
                }
            })();
        }
    };

    useEffect(() => {
        changeCurrentPage({
            heading: 'List Terminals',
            search: true,
        });
    }, [changeCurrentPage]);

    return (
        <div className={styles.container}>
            <div className={styles.menu}>
                <div className={styles.card}>
                    <h3 className={styles.sectionHeading}>Terminal</h3>
                    <div className={styles.services}>
                        <div
                            className={styles.service}
                            onClick={() => {
                                displayModal({
                                    overlay: true,
                                    modal: 'assignTerminal',
                                    service: '',
                                });
                            }}
                        >
                            <img
                                className={styles.serviceLogo}
                                src={userGroup}
                                alt=''
                            />
                            <p className={styles.serviceText}>Add Terminal</p>
                        </div>
                    </div>
                </div>
            </div>
            <ListTerminals
                terminals={terminals}
                handleDeactivateTerminal={handleDeactivateTerminal}
                isUpdated={isUpdated}
                loading={loading}
            />
        </div>
    );
};

const mapStateToProps = (state) => {
    return {
        modalIsUpdated: state.modal.modalIsUpdated,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        changeCurrentPage: (payload) => dispatch(setCurrentPage(payload)),
        displayModal: (payload) => dispatch(setDisplayModal(payload)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Terminals);
