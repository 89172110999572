// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".WalletTransferSummary_container__1mQqK {\n  width: max-content; /* Firefox/Gecko */\n  width: -webkit-max-content; /* Chrome */\n  height: max-content;\n  background-color: #fff;\n  border-radius: 1rem;\n  padding: 4rem 3rem;\n  font-size: 1.4rem;\n}\n@media (max-width: 37.5em) {\n  .WalletTransferSummary_container__1mQqK {\n    width: 95%;\n  }\n}\n@media (max-width: 20.62rem) {\n  .WalletTransferSummary_container__1mQqK {\n    width: 99%;\n  }\n}\n\n.WalletTransferSummary_back__FbvVE {\n  width: max-content;\n  padding: 1rem;\n  position: absolute;\n  top: 2rem;\n  left: 1rem;\n  display: flex;\n  align-items: center;\n  cursor: pointer;\n}\n\n.WalletTransferSummary_backIcon__1SyEc {\n  width: 2rem;\n  margin-right: 0.7rem;\n}\n\n.WalletTransferSummary_backText__2aATJ {\n  font-size: 1.4rem;\n}\n\n.WalletTransferSummary_logoContainer__2aFwd {\n  width: 11rem;\n  height: 5rem;\n  margin: 2rem auto;\n}\n\n.WalletTransferSummary_logo__3qU2t {\n  width: 100%;\n  height: 100%;\n}\n\n.WalletTransferSummary_heading__X5T6E {\n  min-height: 4rem;\n  width: 100%;\n  display: flex;\n  align-items: center;\n  background-color: rgba(193, 207, 226, 0.534);\n  padding: 1rem;\n  border-radius: 0.5rem;\n  color: #2666ff;\n  margin-bottom: 3rem;\n}\n\n.WalletTransferSummary_headingIconContainer__7tyLi {\n  width: 2.5rem;\n  height: 2.5rem;\n  margin-right: 1.5rem;\n  padding: 0.5rem;\n  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.2);\n  background-color: #fff;\n  border-radius: 50%;\n}\n\n.WalletTransferSummary_headingIcon__qSuUa {\n  width: 100%;\n  height: 100%;\n}\n\n.WalletTransferSummary_content__2tQI1 {\n  margin-bottom: 3rem;\n}\n\n.WalletTransferSummary_contentItem__28qCZ {\n  display: flex;\n  justify-content: space-between;\n  align-items: center;\n}\n.WalletTransferSummary_contentItem__28qCZ:not(:last-child) {\n  margin-bottom: 3rem;\n}\n\n.WalletTransferSummary_total__3txRm {\n  font-size: 1.6rem;\n  font-weight: 700;\n}", ""]);
// Exports
exports.locals = {
	"container": "WalletTransferSummary_container__1mQqK",
	"back": "WalletTransferSummary_back__FbvVE",
	"backIcon": "WalletTransferSummary_backIcon__1SyEc",
	"backText": "WalletTransferSummary_backText__2aATJ",
	"logoContainer": "WalletTransferSummary_logoContainer__2aFwd",
	"logo": "WalletTransferSummary_logo__3qU2t",
	"heading": "WalletTransferSummary_heading__X5T6E",
	"headingIconContainer": "WalletTransferSummary_headingIconContainer__7tyLi",
	"headingIcon": "WalletTransferSummary_headingIcon__qSuUa",
	"content": "WalletTransferSummary_content__2tQI1",
	"contentItem": "WalletTransferSummary_contentItem__28qCZ",
	"total": "WalletTransferSummary_total__3txRm"
};
module.exports = exports;
