// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".Contact_container__3806I {\n  padding-top: 5rem;\n}\n.Contact_container__3806I > .Contact_sectionContainer__3XcUA {\n  height: 40.2rem;\n  width: 50.6rem;\n  margin: 0 auto;\n  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.1607843137);\n  background-color: #fff;\n  color: #707070;\n  font-size: 1.4rem;\n  display: flex;\n  padding: 0 3rem;\n  padding-bottom: 3rem;\n  justify-content: space-between;\n  flex-direction: column;\n}\n@media (max-width: 37.5em) {\n  .Contact_container__3806I > .Contact_sectionContainer__3XcUA {\n    width: 95%;\n    padding: 0 1rem;\n  }\n}\n.Contact_container__3806I > .Contact_sectionContainer__3XcUA .Contact_heading__2uAgn {\n  padding: 2rem;\n  flex: 0 0 50px;\n  text-align: center;\n  padding: 2.5rem;\n}\n.Contact_container__3806I > .Contact_sectionContainer__3XcUA .Contact_heading__2uAgn h3 {\n  margin-bottom: 3rem;\n  font-size: 1.7rem;\n}\n.Contact_container__3806I > .Contact_sectionContainer__3XcUA .Contact_heading__2uAgn p {\n  font-size: 1.4rem;\n  margin-bottom: 1rem;\n  line-height: 2.4rem;\n}\n.Contact_container__3806I > .Contact_sectionContainer__3XcUA .Contact_content__1jowB {\n  flex: 1 1;\n  display: flex;\n  flex-wrap: wrap;\n  justify-content: space-around;\n}\n.Contact_container__3806I > .Contact_sectionContainer__3XcUA .Contact_content__1jowB .Contact_section__2FPv1 {\n  flex: 0 0 30%;\n  text-align: center;\n}\n.Contact_container__3806I > .Contact_sectionContainer__3XcUA .Contact_content__1jowB .Contact_section__2FPv1 img {\n  width: 20px;\n  margin-bottom: 10px;\n}\n.Contact_container__3806I > .Contact_sectionContainer__3XcUA .Contact_content__1jowB .Contact_section__2FPv1 p {\n  line-height: 2.4rem;\n}", ""]);
// Exports
exports.locals = {
	"container": "Contact_container__3806I",
	"sectionContainer": "Contact_sectionContainer__3XcUA",
	"heading": "Contact_heading__2uAgn",
	"content": "Contact_content__1jowB",
	"section": "Contact_section__2FPv1"
};
module.exports = exports;
