import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { ThreeDots } from 'svg-loaders-react';
import Axios from 'axios';
import { useToasts } from 'react-toast-notifications';
import { setDisplayModal } from '../../actions/modal';
import axios from 'axios';

import logo from '../../assets/images/logo-colored.png';

import { ASSIGN_TERMINAL } from '../../utils/constants';
import { LIST_TERMINAL_USERS, LIST_AGENT_TERMINAL } from '../../utils/constants';
import { setCurrentPage } from '../../actions/page';

import Form from '../../components/common/Form';
import FormGroup from '../../components/common/FormGroup';
import Submit from '../../components/common/Button';
import Select from 'react-select'

export const AssignTerminal = ({ changeCurrentPage, displayModal }) => {
    const { addToast } = useToasts();
    changeCurrentPage({
        heading: 'Assign Terminal',
        search: false,
    });

    const [validationErrors, setValidationErrors] = useState({});
    const [formState, setFormState] = useState({
        user_id: '',
        terminal_id: ''
    });
    const [loading, setLoading] = useState(false);
    const [users, setUsers] = useState([]);
    const [terminals, setTerminals] = useState([]);

    const styleConfiguration = {
        control: base => ({
            ...base,
            fontSize: '1.5rem',
            fontFamily: 'inherit'
        }),
        input: base => ({
            ...base,
            fontSize: '1.5rem',
            fontFamily: 'inherit'
        }),
        option: base => ({
            ...base,
            fontSize: '1.5rem',
            fontFamily: 'inherit'
        }),
    }

    useEffect(() => {
        let isCancelled;

        (async function listUsers() {
            setLoading(true);

            try {
                const res = await axios.get(LIST_TERMINAL_USERS);

                if (!isCancelled) {
                    setUsers(res?.data?.data);
                }
            } catch (e) {
                // console.log(e);
            } finally {
                setLoading(false);
            }
        })();

        (async function listTerminals() {
            setLoading(true);

            try {
                const res = await axios.get(`${LIST_AGENT_TERMINAL}?terminal_status=Unassigned`);

                if (!isCancelled) {
                    setTerminals(res?.data?.data);
                }
            } catch (e) {
                // console.log(e);
            } finally {
                setLoading(false);
            }
        })();

        return () => {
            isCancelled = true;
        };
    }, []);

    const handleSelectUserStateChange = (target) => {
        setValidationErrors({ ...validationErrors, ['user_id']: false });
        setFormState({ ...formState, ['user_id']: target.value });
    };

    const handleSelectUserTerminalChange = (target) => {
        setValidationErrors({ ...validationErrors, ['terminal_id']: false });
        setFormState({ ...formState, ['terminal_id']: target.value });
    };

    const handleOnSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);

        try {
            const res = await Axios.post(`${ASSIGN_TERMINAL}/${formState.terminal_id}/users/${formState.user_id}/assign`, []);

            addToast('Terminal assigned successfully', {
                appearance: 'success',
                autoDismiss: false,
            });

            displayModal({
                overlay: false,
                modal: '',
                service: '',
                modalIsUpdated: res.data.data.id,
            });

            setLoading(false);
        } catch (e) {
            setLoading(false);

            addToast('An error occured', {
                appearance: 'error',
                autoDismiss: false,
            });
        }
    };

    return (
        <Form
            autoComplete='off'
            title='Assign Terminal'
            caption='Assign Terminal to User'
            handleOnSubmit={handleOnSubmit}
            logo={logo}
        >
            <FormGroup>
                <label style={
                    {
                        'fontSize': '1.4rem',
                        'lineHeight': '16px',
                        'letterSpacing': '-0.3px',
                        'fontWeight': '600',
                        'color': '#707070',
                        'marginBottom': '1.5rem',
                        'display': 'inline-block',
                        'padding': '0 0.1rem',
                        'backgroundColor': '#fff',
                        'left': '1.6rem',
                        'transition': '0.2s all'
                    }
                }>Select User <span style={{ color: 'red' }}>*</span></label>
                <Select
                    styles={styleConfiguration}
                    required
                    name="user_id"
                    onChange={handleSelectUserStateChange}
                    options={users.map((user) => { return { value: user.id, label: `${user.name}` } })}
                />
            </FormGroup>
            <FormGroup>
                <label style={
                    {
                        'fontSize': '1.4rem',
                        'lineHeight': '16px',
                        'letterSpacing': '-0.3px',
                        'fontWeight': '600',
                        'color': '#707070',
                        'marginBottom': '1.5rem',
                        'display': 'inline-block',
                        'padding': '0 0.1rem',
                        'backgroundColor': '#fff',
                        'left': '1.6rem',
                        'transition': '0.2s all'
                    }
                }>Select Terminal <span style={{ color: 'red' }}>*</span></label>
                <Select
                    styles={styleConfiguration}
                    required
                    name="terminal_id"
                    onChange={handleSelectUserTerminalChange}
                    options={terminals.map((terminal) => { return { value: terminal.id, label: `${terminal.terminal_id} - (${terminal.serial_number})` } })}
                />
            </FormGroup>
            <Submit type='submit'>{loading ? <ThreeDots /> : 'Assign'}</Submit>
        </Form>
    );
};

const mapDispatchToProps = (dispatch) => {
    return {
        changeCurrentPage: (payload) => dispatch(setCurrentPage(payload)),
        displayModal: (payload) => dispatch(setDisplayModal(payload)),
    };
};

export default connect(undefined, mapDispatchToProps)(AssignTerminal);
