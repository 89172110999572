// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".ErrorPage_errorContainer__3vz-g {\n  display: flex;\n  flex-direction: column;\n  justify-content: center;\n}\n.ErrorPage_errorContainer__3vz-g .ErrorPage_errorCenter__3l4Nj {\n  margin: 0 20%;\n  margin-top: 15%;\n}\n@media (max-width: 700px) {\n  .ErrorPage_errorContainer__3vz-g .ErrorPage_errorCenter__3l4Nj {\n    margin: 0 10%;\n    margin-top: 25%;\n  }\n}\n.ErrorPage_errorContainer__3vz-g .ErrorPage_errorCenter__3l4Nj h1 {\n  font-size: 9rem;\n  font-weight: bolder;\n  text-align: center;\n}\n.ErrorPage_errorContainer__3vz-g .ErrorPage_errorCenter__3l4Nj p {\n  font-size: 2rem;\n  text-align: center;\n  font-weight: 500;\n}\n.ErrorPage_errorContainer__3vz-g .ErrorPage_errorCenter__3l4Nj .ErrorPage_errorActions__1khqt {\n  margin-top: 5%;\n  display: flex;\n  justify-content: center;\n}\n.ErrorPage_errorContainer__3vz-g .ErrorPage_errorCenter__3l4Nj .ErrorPage_errorActions__1khqt > button {\n  width: 50%;\n}", ""]);
// Exports
exports.locals = {
	"errorContainer": "ErrorPage_errorContainer__3vz-g",
	"errorCenter": "ErrorPage_errorCenter__3l4Nj",
	"errorActions": "ErrorPage_errorActions__1khqt"
};
module.exports = exports;
