// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".users_container__3ds6K {\n  padding: 3rem 1rem;\n  background-color: #fff;\n}\n@media (max-width: 56.25em) {\n  .users_container__3ds6K {\n    padding: 1.5rem 0;\n  }\n}\n\n.users_menu__Yk0LG {\n  width: 100%;\n  display: flex;\n  flex-wrap: wrap;\n  justify-content: flex-start;\n  margin-bottom: 3rem;\n}\n@media (max-width: 56.25em) {\n  .users_menu__Yk0LG {\n    justify-content: flex-start;\n  }\n}\n\n.users_card__3FDoO {\n  width: 15rem;\n  height: 13rem;\n  padding: 1rem;\n  background: #ffffff;\n  box-shadow: 0px 2px 2px -2px rgba(0, 0, 0, 0.25);\n  border-radius: 5px;\n}\n.users_sectionHeading__1h3R0 {\n  margin-bottom: 1.5rem;\n  font-size: 1.4rem;\n  font-family: \"Nunito\", sans-serif;\n  color: #22215b;\n}\n\n.users_services__30UYs {\n  display: flex;\n  justify-content: space-around;\n  align-items: center;\n}\n\n.users_service__2WBNd {\n  text-align: center;\n  width: 10rem;\n  padding: 1rem;\n  background: rgba(155, 180, 255, 0.1);\n  border-radius: 5px;\n  cursor: pointer;\n}\n\n.users_serviceLogo__ZZxSL {\n  width: 3rem;\n  margin-bottom: 1rem;\n}\n\n.users_serviceText__275Jm {\n  font-size: 1.2rem;\n  text-align: center;\n  color: #22215b;\n}", ""]);
// Exports
exports.locals = {
	"container": "users_container__3ds6K",
	"menu": "users_menu__Yk0LG",
	"card": "users_card__3FDoO",
	"sectionHeading": "users_sectionHeading__1h3R0",
	"services": "users_services__30UYs",
	"service": "users_service__2WBNd",
	"serviceLogo": "users_serviceLogo__ZZxSL",
	"serviceText": "users_serviceText__275Jm"
};
module.exports = exports;
