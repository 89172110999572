// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".WalletTransferStatus_container__7Vpj9 {\n  width: max-content; /* Firefox/Gecko */\n  width: -webkit-max-content; /* Chrome */\n  height: max-content;\n  background-color: #fff;\n  border-radius: 1rem;\n  padding: 4rem 3rem;\n  font-size: 1.4rem;\n}\n@media (max-width: 37.5em) {\n  .WalletTransferStatus_container__7Vpj9 {\n    width: 95%;\n  }\n}\n@media (max-width: 20.62rem) {\n  .WalletTransferStatus_container__7Vpj9 {\n    width: 99%;\n  }\n}\n\n.WalletTransferStatus_back__2V6LX {\n  width: max-content;\n  padding: 1rem;\n  position: absolute;\n  top: 2rem;\n  left: 1rem;\n  display: flex;\n  align-items: center;\n  cursor: pointer;\n}\n\n.WalletTransferStatus_backIcon__236Hd {\n  width: 2rem;\n  margin-right: 0.7rem;\n}\n\n.WalletTransferStatus_backText__2XDwj {\n  font-size: 1.4rem;\n}\n\n.WalletTransferStatus_logoContainer__1YFuc {\n  width: 11rem;\n  height: 5rem;\n  margin: 2rem auto;\n}\n\n.WalletTransferStatus_logo__oj0kp {\n  width: 100%;\n  height: 100%;\n}\n\n.WalletTransferStatus_heading__1aW1y {\n  min-height: 4rem;\n  width: 100%;\n  display: flex;\n  align-items: center;\n  background-color: rgba(193, 207, 226, 0.534);\n  padding: 1rem;\n  border-radius: 0.5rem;\n  color: #2666ff;\n  margin-bottom: 3rem;\n}\n\n.WalletTransferStatus_headingIconContainer__EtO5T {\n  width: 2.5rem;\n  height: 2.5rem;\n  margin-right: 1.5rem;\n  padding: 0.5rem;\n  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.2);\n  background-color: #fff;\n  border-radius: 50%;\n}\n\n.WalletTransferStatus_headingIcon__3j1a_ {\n  width: 100%;\n  height: 100%;\n}\n\n.WalletTransferStatus_content__gaFnB {\n  margin-bottom: 3rem;\n}\n\n.WalletTransferStatus_contentItem__2VBP7 {\n  display: flex;\n  justify-content: space-between;\n  align-items: center;\n}\n.WalletTransferStatus_contentItem__2VBP7:not(:last-child) {\n  margin-bottom: 3rem;\n}\n\n.WalletTransferStatus_total__2EcOd {\n  font-size: 1.6rem;\n  font-weight: 700;\n}", ""]);
// Exports
exports.locals = {
	"container": "WalletTransferStatus_container__7Vpj9",
	"back": "WalletTransferStatus_back__2V6LX",
	"backIcon": "WalletTransferStatus_backIcon__236Hd",
	"backText": "WalletTransferStatus_backText__2XDwj",
	"logoContainer": "WalletTransferStatus_logoContainer__1YFuc",
	"logo": "WalletTransferStatus_logo__oj0kp",
	"heading": "WalletTransferStatus_heading__1aW1y",
	"headingIconContainer": "WalletTransferStatus_headingIconContainer__EtO5T",
	"headingIcon": "WalletTransferStatus_headingIcon__3j1a_",
	"content": "WalletTransferStatus_content__gaFnB",
	"contentItem": "WalletTransferStatus_contentItem__2VBP7",
	"total": "WalletTransferStatus_total__2EcOd"
};
module.exports = exports;
