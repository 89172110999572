// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".Loan_container__Ywmj1 {\n  padding-top: 2rem;\n}\n\n.Loan_section__2gSyH {\n  margin-bottom: 2rem;\n}\n\n.Loan_sectionHeading__1PHvg {\n  margin-bottom: 1.5rem;\n  font-size: 1.4rem;\n  font-family: \"Nunito\", sans-serif;\n  color: #22215b;\n}\n\n.Loan_card__SkQ_U {\n  width: 35rem;\n  height: 30rem;\n  padding: 1.5rem 2.5rem;\n  background: #ffffff;\n  box-shadow: 0px 2px 2px -2px rgba(0, 0, 0, 0.25);\n  border-radius: 5px;\n  flex-wrap: wrap;\n}\n@media (max-width: 37.5em) {\n  .Loan_card__SkQ_U {\n    width: 100%;\n  }\n}\n\n.Loan_services__1qTAM {\n  display: flex;\n  justify-content: space-around;\n  align-items: center;\n  height: 90%;\n  width: 100%;\n  display: grid;\n  grid-template-columns: repeat(2, 1fr);\n  grid-template-rows: repeat(2, 1fr);\n  grid-gap: 3rem;\n  gap: 3rem;\n}\n\n.Loan_service__rJm-H {\n  text-align: center;\n  padding: 1rem;\n  background: rgba(155, 180, 255, 0.1);\n  border-radius: 5px;\n  cursor: pointer;\n  height: 100%;\n  width: 100%;\n  display: flex;\n  flex-direction: column;\n  justify-content: space-between;\n  align-items: center;\n  text-decoration: none;\n}\n\n.Loan_serviceLogoContainer__25_wl {\n  width: 5rem;\n  height: 5rem;\n  padding: 1rem;\n  border-radius: 50%;\n}\n.Loan_serviceLogoContainerAgent__2z2Kl {\n  background: rgba(89, 244, 86, 0.2);\n}\n.Loan_serviceLogoContainerConsumer__3dCva {\n  background: #c6d4ff;\n}\n.Loan_serviceLogoContainerHistory__386Ie {\n  background: #fff1e1;\n}\n.Loan_serviceLogoContainerRepayment__2Ld9x {\n  background: #ffc6c6;\n}\n\n.Loan_serviceLogo__3TAFg {\n  width: 100%;\n  height: 100%;\n}\n\n.Loan_serviceText__1uDmI {\n  font-size: 1.2rem;\n  text-align: center;\n  color: #22215b;\n}", ""]);
// Exports
exports.locals = {
	"container": "Loan_container__Ywmj1",
	"section": "Loan_section__2gSyH",
	"sectionHeading": "Loan_sectionHeading__1PHvg",
	"card": "Loan_card__SkQ_U",
	"services": "Loan_services__1qTAM",
	"service": "Loan_service__rJm-H",
	"serviceLogoContainer": "Loan_serviceLogoContainer__25_wl",
	"serviceLogoContainerAgent": "Loan_serviceLogoContainerAgent__2z2Kl",
	"serviceLogoContainerConsumer": "Loan_serviceLogoContainerConsumer__3dCva",
	"serviceLogoContainerHistory": "Loan_serviceLogoContainerHistory__386Ie",
	"serviceLogoContainerRepayment": "Loan_serviceLogoContainerRepayment__2Ld9x",
	"serviceLogo": "Loan_serviceLogo__3TAFg",
	"serviceText": "Loan_serviceText__1uDmI"
};
module.exports = exports;
