import React, { useState} from "react";
import { Link } from "react-router-dom";

import menu from "../../assets/images/dots.svg";
import { ThreeDots } from "svg-loaders-react";

import refresh from "../../assets/images/refresh.svg";

import terminalStyles from "./ListTerminals.module.scss";

const Terminal = ({ handleDeactivateTerminal, loading, terminals }) => {
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [isOpen, setIsOpen] = useState(false);


  const CustomInputGroup = ({ value, onClick, label }) => {
    return (
      <label className={terminalStyles.CustomInputGroup}>
        <span className={terminalStyles.customInputGroupLabel}>{label}:</span>
        <input type="text" value={value} onClick={onClick} />
      </label>
    );
  };

  return (
    <>
      <div className={terminalStyles.users}>
        {terminals.length > 0 && !loading ? (
          <div className={terminalStyles.transactions}>
            <h3 className={terminalStyles.transactionsHeading}>Terminals</h3>
            <div className={terminalStyles.table}>
              <div className={terminalStyles.tableHeading}>
                <span className={terminalStyles.sn}>S/N</span>
                <span className={terminalStyles.username}>TID</span>
                <span className={terminalStyles.phone}>Serial Number</span>
                <span className={terminalStyles.email}>Name</span>
                <span className={terminalStyles.email}>Terminal Status</span>
                <span className={terminalStyles.date}>Date Added</span>
                <span className={terminalStyles.action}>Action</span>
              </div>
              <div className={terminalStyles.tableBody}>
                {terminals.map((terminal, index) => {
                  const date = new Date(terminal.created_at).toDateString();
                  const formattedDate = date.slice(4);

                  return (
                    <div className={terminalStyles.tableRow} key={index}>
                      <span className={terminalStyles.sn}>{++index}.</span>
                      <span className={terminalStyles.username}>
                        {terminal.terminal_id || "Nil"}
                      </span>
                      <span className={terminalStyles.phone}>{terminal.serial_number}</span>
                      <span className={terminalStyles.email}>{terminal.user.name}</span>
                      <span className={terminalStyles.email}>{terminal.terminal_status}</span>
                      <span className={terminalStyles.date}>{formattedDate}</span>
                      {terminal.terminal_status == 'Assigned' && (
                        <div className={terminalStyles.action}>
                        <label htmlFor={`menu${index}`}>
                          <img className={terminalStyles.menu} src={menu} alt="" />
                        </label>
                        <input
                          name={`menu${index}`}
                          id={`menu${index}`}
                          type="checkbox"
                        />

                        <div className={terminalStyles.actions}>
                          <span
                            className={terminalStyles.actionsItem}
                            onClick={() => {
                              handleDeactivateTerminal(terminal.id, terminal.user.id);
                            }}
                          >
                            Deactivate
                          </span>
                        </div>
                      </div>
                      )}
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        ) : loading ? (
          <ThreeDots fill="#3E215B" />
        ) : (
          <div style={{ fontSize: "16px" }}>No terminals to display</div>
        )}
      </div>
    </>
  );
};

export default Terminal;
