// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".Overlay_container__2TwBt {\n  background-color: rgba(115, 129, 150, 0.637);\n  width: 100%;\n  height: 100%;\n  overflow: auto;\n  position: relative;\n}", ""]);
// Exports
exports.locals = {
	"container": "Overlay_container__2TwBt"
};
module.exports = exports;
