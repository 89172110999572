// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".wallet_container__3z17x {\n  padding: 3rem 1rem;\n  background-color: #fff;\n}\n@media (max-width: 56.25em) {\n  .wallet_container__3z17x {\n    padding: 1.5rem 0;\n  }\n}\n\n.wallet_menu__1Njwa {\n  width: 100%;\n  display: flex;\n  flex-wrap: wrap;\n  justify-content: flex-start;\n  margin-bottom: 1rem;\n}\n@media (max-width: 56.25em) {\n  .wallet_menu__1Njwa {\n    justify-content: flex-start;\n  }\n}\n\n.wallet_card__3-llU {\n  width: 15rem;\n  height: 13rem;\n  padding: 1rem;\n  background: #ffffff;\n  box-shadow: 0px 2px 2px -2px rgba(0, 0, 0, 0.25);\n  border-radius: 5px;\n}\n.wallet_sectionHeading__lbV1z {\n  margin-bottom: 1.5rem;\n  font-size: 1.4rem;\n  font-family: \"Nunito\", sans-serif;\n  color: #22215b;\n}\n\n.wallet_services__36fbj {\n  display: flex;\n  justify-content: space-around;\n  align-items: center;\n}\n\n.wallet_service__2DLAM {\n  text-align: center;\n  width: 12rem;\n  padding: 1rem;\n  background: rgba(155, 180, 255, 0.1);\n  border-radius: 5px;\n  cursor: pointer;\n}\n\n.wallet_serviceLogo__2_0Tq {\n  width: 3rem;\n  margin-bottom: 1rem;\n}\n\n.wallet_serviceText___NsR2 {\n  font-size: 1.2rem;\n  text-align: center;\n  color: #22215b;\n}", ""]);
// Exports
exports.locals = {
	"container": "wallet_container__3z17x",
	"menu": "wallet_menu__1Njwa",
	"card": "wallet_card__3-llU",
	"sectionHeading": "wallet_sectionHeading__lbV1z",
	"services": "wallet_services__36fbj",
	"service": "wallet_service__2DLAM",
	"serviceLogo": "wallet_serviceLogo__2_0Tq",
	"serviceText": "wallet_serviceText___NsR2"
};
module.exports = exports;
