import React from "react";
import { NavLink } from "react-router-dom";

import logoWhite from "../../assets/icons/logo-white.png";

import styles from "./HomeNavBar.module.scss";

const HomeNavBar = ({ theme }) => {
  return (
    <nav className={styles.wrapper}>
      <ul className={styles.list}>
        <li className={`${styles.listItem} ${styles.listItemHome}`}>
          <NavLink
            to="/"
            className={`${styles.listItemLink} ${styles.listItemHomeLink}`}
          >
            <img
              className={styles.logo}
              src={logoWhite}
              alt=""
            />
          </NavLink>
        </li>
      </ul>
    </nav>
  );
};

export default HomeNavBar;
