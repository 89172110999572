import React, { useState, useEffect, useReducer } from 'react';
import axios from 'axios';
import { useToasts } from 'react-toast-notifications';
import { ThreeDots } from 'svg-loaders-react';
import { connect } from 'react-redux';
import _ from 'lodash';

import styles from './index.module.scss';
import Uploader from './Upload';
import { AGENT_DOCUMENT, REGISTRATION_SELECT } from '../../../utils/constants';
import moment from 'moment';
import LoadingOverlay from 'react-loading-overlay';
import UploadReducer, { initialState } from './upload-reducer.js';
import { setDisplayModal } from '../../../actions/modal';

const Document = ({ agent_code, displayModal, agent_id }) => {
    // const [utility, setUtility] = React.useState({});
    // const [passport, setPassport] = React.useState({});
    // const [id, setID] = React.useState({});
    // const [guarantor, setGuarantor] = React.useState({});
    const [documents, setDocuments] = React.useState([]);
    const [uploaded, setUploaded] = React.useState([]);
    const [rejected, setRejected] = React.useState([]);
    const [pending, setPending] = React.useState([]);
    const [selectables, setSelectables] = React.useState([]);
    const [remainingDocuments, setRemainingDocuments] = React.useState([]);
    const [showReason, setShowReason] = useState({
        reason: false,
        reasonData: [],
    });
    const [isOverlayActive, setOverlayActive] = useState(false);
    const [loading, setLoading] = useState(false);
    const [uploadState, dispatch] = useReducer(UploadReducer, initialState);
    const { addToast } = useToasts();

    const handleShowReason = (data) => {
        if (data.status === 'rejected')
            return setShowReason({
                reason: !showReason.reason,
                data,
            });
    };

    useEffect(() => {
        setOverlayActive(true);
        (async function getUploadedDocument() {
            try {
                const res = await axios.get(AGENT_DOCUMENT);
                setUploaded(res?.data?.data?.approved_documents);
                setRejected(res?.data?.data?.rejected_documents);
                setPending(res?.data?.data?.pending_documents);
                setRemainingDocuments(res?.data?.data?.remaining_agent_document_types)
                setOverlayActive(false);
            } catch (e) {
                addToast('An error occured, kindly reload', {
                    appearance: 'error',
                    autoDismiss: true,
                });
                setOverlayActive(false);
            }
        })();
    }, []);

    useEffect(() => {
        getSelectables()
    }, [])

    const getSelectables = async () => {
        try {
            const res = await axios.get(REGISTRATION_SELECT);
            setSelectables(res.data.data);
        } catch {
            addToast('An error occured, kindly reload', {
                appearance: 'error',
                autoDismiss: true,
            });
            setOverlayActive(false);
        }
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        setLoading(true);

        (async function disburseFunds() {

            const payload = {
                agent_id,
                documents: documents,
            };

            if (payload.documents.length < 1) {
                addToast('The documents field is required.', {
                    appearance: 'error',
                    autoDismiss: true,
                });
                setLoading(false);
                return;
            }

            try {
                const res = await axios.post(AGENT_DOCUMENT, payload);

                setLoading(false);
                addToast(res.data.message, {
                    appearance: 'success',
                    autoDismiss: true,
                });
                window.location.reload();
            } catch (err) {
                if (err.response && err.response.status === 403) {
                    setLoading(false);
                    addToast(err.response.statusText, {
                        appearance: 'error',
                        autoDismiss: true,
                    });
                } else if (err.response && err.response.status === 401) {
                    setLoading(false);
                    addToast(err.response.statusText, {
                        appearance: 'error',
                        autoDismiss: true,
                    });
                } else if (err.response && err.response.status === 500) {
                    setLoading(false);
                    addToast(err.response.statusText, {
                        appearance: 'error',
                        autoDismiss: true,
                    });
                } else if (err.response && err.response.status === 412) {
                    setLoading(false);
                    addToast(err.response.data.error[0], {
                        appearance: 'error',
                        autoDismiss: true,
                    });
                } else {
                    setTimeout(() => {
                        setLoading(false);
                    }, 7000);
                }
            }
        })();
    };

    return (
        <LoadingOverlay
            active={isOverlayActive}
            spinner
            text={'fetching uploaded document'}
        >
            <div className={styles.container}>
                <div className={styles.documents}>
                    <h3 className={styles.documentsHeading}>
                        Documents Management
                    </h3>
                    <div className={styles.documentsUploaded}>
                        <h5 className={styles.documentsUploadedHeadings}>
                            Approved Uploaded Documents
                        </h5>
                        {uploaded.length < 1 ? (
                            <p className={styles.documentsUploadedText}>
                                No document have been approved yet
                            </p>
                        ) : (
                            <div className={styles.uploadedDocument}>
                                {uploaded &&
                                    uploaded.map((data, index) => (
                                        <div
                                            className={styles.image}
                                            key={index}
                                        >
                                            <img
                                                src={data.file_url}
                                                alt=''
                                                onClick={() =>
                                                    displayModal({
                                                        overlay: true,
                                                        modal: 'showUpload',
                                                        service: data.file_url,
                                                    })
                                                }
                                            />
                                            <p>{selectables?.agent_document_types?.find((document_type) => document_type?.id == data?.agent_document_type_id)?.name}</p>
                                            <span
                                                onClick={() =>
                                                    handleShowReason(data)
                                                }
                                                className={`${
                                                    data.status === 'Rejected'
                                                        ? styles.red
                                                        : data.status ===
                                                          'Approved'
                                                        ? styles.green
                                                        : styles.grey
                                                }`}
                                            >
                                                {data.status}
                                                {/* {data.status === 'Rejected' &&
                                                    `(${data?.docs_reasons?.length})`} */}
                                            </span>

                                            {showReason.reason && (
                                                <ul className={styles.reason}>
                                                    {showReason.data.id ===
                                                        data.id &&
                                                        showReason.data?.docs_reasons.map(
                                                            (res, index) => (
                                                                <li key={index}>
                                                                    <span
                                                                        style={{
                                                                            marginRight:
                                                                                '10px',
                                                                        }}
                                                                    >
                                                                        {moment(
                                                                            res.updated_at
                                                                        ).fromNow()}{' '}
                                                                        {'--'}
                                                                    </span>
                                                                    {res.reason}
                                                                </li>
                                                            )
                                                        )}
                                                </ul>
                                            )}
                                        </div>
                                    ))}
                            </div>
                        )}
                    </div>

                    <div className={styles.documentsUploaded}>
                        <h5 className={styles.documentsUploadedHeadings}>
                            Documents Pending Review
                        </h5>
                        {pending.length < 1 ? (
                            <p className={styles.documentsUploadedText}>
                                No document is pending review
                            </p>
                        ) : (
                            <div className={styles.uploadedDocument}>
                                {pending &&
                                    pending.map((data, index) => (
                                        <div
                                            className={styles.image}
                                            key={index}
                                        >
                                            <img
                                                src={data.file_url}
                                                alt=''
                                                onClick={() =>
                                                    displayModal({
                                                        overlay: true,
                                                        modal: 'showUpload',
                                                        service: data.file_url,
                                                    })
                                                }
                                            />
                                            <p>{selectables?.agent_document_types?.find((document_type) => document_type?.id == data?.agent_document_type_id)?.name}</p>
                                            <span
                                                onClick={() =>
                                                    handleShowReason(data)
                                                }
                                                className={`${
                                                    data.status === 'Rejected'
                                                        ? styles.red
                                                        : data.status ===
                                                          'Approved'
                                                        ? styles.green
                                                        : styles.grey
                                                }`}
                                            >
                                                {data.status}
                                                {/* {data.status === 'Rejected' &&
                                                    `(${data?.docs_reasons?.length})`} */}
                                            </span>
                                        </div>
                                    ))}
                            </div>
                        )}
                    </div>

                    <div className={styles.documentsUploaded}>
                        <h5 className={styles.documentsUploadedHeadings}>
                            Rejected Uploaded Documents
                        </h5>
                        {rejected.length < 1 ? (
                            <p className={styles.documentsUploadedText}>
                                No documents has been rejected yet.
                            </p>
                        ) : (
                            <div className={styles.uploadedDocument}>
                                {rejected &&
                                    rejected.map((data, index) => (
                                        <div
                                            className={styles.image}
                                            key={index}
                                        >
                                            <img
                                                src={data.file_url}
                                                alt=''
                                                onClick={() =>
                                                    displayModal({
                                                        overlay: true,
                                                        modal: 'showUpload',
                                                        service: data.file_url,
                                                    })
                                                }
                                            />
                                            <p>{selectables?.agent_document_types?.find((document_type) => document_type?.id == data?.agent_document_type_id)?.name}</p>
                                            <span
                                                onClick={() =>
                                                    handleShowReason(data)
                                                }
                                                className={`${
                                                    data.status === 'Rejected'
                                                        ? styles.red
                                                        : data.status ===
                                                          'Approved'
                                                        ? styles.green
                                                        : styles.grey
                                                }`}
                                            >
                                                {data.status}
                                                {/* {data.status === 'Rejected' &&
                                                    `(${data?.docs_reasons?.length})`} */}
                                            </span>
                                        </div>
                                    ))}
                            </div>
                        )}
                    </div>

                    {remainingDocuments.length > 0 && (
                        <div className={styles.documentsUpload}>
                            <h5 className={styles.documentsUploadHeadings}>
                                Upload Document
                            </h5>

                            <form className={styles.form}>
                                {remainingDocuments.map((remainingDocument, index) => (
                                        <div
                                            className={`${
                                                styles.formGroup
                                            }`}
                                            key={index}
                                        >
                                            <label
                                                className={styles.label}
                                                htmlFor='firstname'
                                            >
                                                {remainingDocument?.name}
                                                <span className={styles.fileFormat}>
                                                    (jpeg, jpg, png)
                                                </span>
                                            </label>
                                            <Uploader
                                                remainingDocument={remainingDocument}
                                                setDocuments={setDocuments}
                                                documents={documents}
                                                agent_code={agent_code}
                                            />
                                        </div>
                                    ))
                                }

                                
                                <div
                                    className={`${styles.submit} ${styles.formGroup}`}
                                    onClick={handleSubmit}
                                >
                                    <button className={styles.submit} type='submit'>
                                        {loading ? <ThreeDots /> : 'Submit'}
                                    </button>
                                </div>
                            </form>
                        </div>
                    )}
                </div>
            </div>
        </LoadingOverlay>
    );
};

const mapStateToProps = (state) => {
    return {
        agent_code: state.auth.user.agent_code,
        agent_id: state.auth.user.agent_id
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        displayModal: (payload) => dispatch(setDisplayModal(payload)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Document);
