// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".HomeNavBar_wrapper__3Xft1 {\n  height: 8rem;\n}\n\n.HomeNavBar_list__19BbV {\n  width: 100%;\n  height: 100%;\n  display: flex;\n  align-items: center;\n  justify-content: space-between;\n  padding: 0 5%;\n  list-style: none;\n}\n\n.HomeNavBar_listItem__SAx6w {\n  flex: 0 0 14rem;\n  min-width: 6rem;\n  text-align: center;\n}\n.HomeNavBar_listItem__SAx6w:not(:first-child) {\n  margin-left: 3rem;\n}\n\n.HomeNavBar_listItem__SAx6w .HomeNavBar_logo__11Gfi {\n  width: 100%;\n  height: 100%;\n}\n\n.HomeNavBar_listItemLink__3fZ3F {\n  box-sizing: content-box;\n  height: 3.8rem;\n  width: 100%;\n  line-height: 3.8rem;\n  display: inline-block;\n  text-decoration: none;\n  color: #fff;\n  font-size: 1.4rem;\n  font-weight: 600;\n}", ""]);
// Exports
exports.locals = {
	"wrapper": "HomeNavBar_wrapper__3Xft1",
	"list": "HomeNavBar_list__19BbV",
	"listItem": "HomeNavBar_listItem__SAx6w",
	"logo": "HomeNavBar_logo__11Gfi",
	"listItemLink": "HomeNavBar_listItemLink__3fZ3F"
};
module.exports = exports;
