// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".FormGroup_group__3uQNI {\n  width: 100%;\n  position: relative;\n}\n.FormGroup_group__3uQNI > svg {\n  position: absolute;\n  top: 50%;\n  left: 1rem;\n  width: 3rem;\n}\n\n.FormGroup_default__GcRbP {\n  display: initial;\n}\n\n.FormGroup_flex__2aVtZ {\n  display: flex;\n  justify-content: space-between;\n  align-items: center;\n}", ""]);
// Exports
exports.locals = {
	"group": "FormGroup_group__3uQNI",
	"default": "FormGroup_default__GcRbP",
	"flex": "FormGroup_flex__2aVtZ"
};
module.exports = exports;
