// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".AccountDetails_form__2qbPc {\n  padding: 3rem 2.5rem;\n  margin: 0 auto;\n  display: grid;\n  grid-template-columns: repeat(2, 1fr);\n  grid-gap: 3rem;\n}\n@media (max-width: 500px) {\n  .AccountDetails_form__2qbPc {\n    grid-template-columns: none;\n    grid-gap: 2rem;\n    padding-top: 4rem;\n    padding-bottom: 1rem;\n  }\n}\n\n.AccountDetails_formGroup__37YCa {\n  text-align: left;\n}\n\n.AccountDetails_label__3g2yI {\n  margin-bottom: 1rem;\n  color: #3d3d3d;\n  font-size: 1.5rem;\n}\n\n.AccountDetails_input__2tAdu,\n.AccountDetails_select__yomor {\n  margin-top: 0.8rem;\n  display: block;\n  border: 1px solid #c4c4c4;\n  padding: 0 1rem;\n  font-family: \"Avenir\";\n  color: #3d3d3d;\n  width: 100%;\n  height: 4rem;\n  border-radius: 5px;\n  font-size: 1.6rem;\n  background: #fff;\n}\n\n.AccountDetails_errorText__3_ROA {\n  font-size: 1.4rem;\n  color: rgb(226, 42, 42);\n  margin-top: 0.5rem;\n}\n\n@media (max-width: 20.62rem) {\n  .AccountDetails_submit__3gM34 {\n    margin-top: 3rem;\n  }\n}\n\n.AccountDetails_button__11VbK {\n  color: #3d3d3d;\n}\n\n.AccountDetails_submit__3gM34 {\n  grid-column: 1/-1;\n  margin-top: 2rem;\n  display: grid;\n  grid-template-columns: repeat(2, 1fr);\n  grid-gap: 3rem;\n}\n@media (max-width: 20.62rem) {\n  .AccountDetails_submit__3gM34 {\n    margin-top: 3rem;\n  }\n}\n.AccountDetails_submit__3gM34 .AccountDetails_button__11VbK {\n  flex: 0 0 44%;\n  height: 4rem;\n  border-radius: 5px;\n  border: 0;\n  background-color: #E4AA3D;\n  color: #eee;\n  cursor: pointer;\n  outline: none;\n  font-size: 1.6rem;\n}\n.AccountDetails_submit__3gM34 .AccountDetails_button__11VbK svg {\n  width: 3rem;\n  position: relative;\n  top: 2px;\n}\n.AccountDetails_submit__3gM34 .AccountDetails_back__3vo45 {\n  background-color: #fff;\n  color: #E4AA3D;\n  border: 1px solid #E4AA3D !important;\n}", ""]);
// Exports
exports.locals = {
	"form": "AccountDetails_form__2qbPc",
	"formGroup": "AccountDetails_formGroup__37YCa",
	"label": "AccountDetails_label__3g2yI",
	"input": "AccountDetails_input__2tAdu",
	"select": "AccountDetails_select__yomor",
	"errorText": "AccountDetails_errorText__3_ROA",
	"submit": "AccountDetails_submit__3gM34",
	"button": "AccountDetails_button__11VbK",
	"back": "AccountDetails_back__3vo45"
};
module.exports = exports;
