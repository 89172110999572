// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".BillPayment_container__1jLpU {\n  padding-top: 2rem;\n  height: 100%;\n}\n\n.BillPayment_section__2TDLw {\n  margin-bottom: 2rem;\n}\n\n.BillPayment_sectionHeading__ZAr8_ {\n  margin-bottom: 1.5rem;\n  font-size: 1.3rem;\n  color: #22215b;\n}\n@media (max-width: 37.5em) {\n  .BillPayment_sectionHeading__ZAr8_ {\n    margin-bottom: 0;\n  }\n}\n\n.BillPayment_card__2Wo4_ {\n  width: 38rem;\n  min-height: 13rem;\n  padding: 1rem;\n  background: #ffffff;\n  box-shadow: 0px 2px 2px -2px rgba(0, 0, 0, 0.25);\n  border-radius: 5px;\n}\n.BillPayment_cardElectricity__d3ZBu {\n  width: 75rem;\n}\n@media (max-width: 56.25em) {\n  .BillPayment_cardElectricity__d3ZBu {\n    width: 100%;\n  }\n}\n@media (max-width: 37.5em) {\n  .BillPayment_card__2Wo4_ {\n    width: 100%;\n  }\n}\n\n.BillPayment_services__iGS1G {\n  width: 100%;\n  display: flex;\n  justify-content: space-around;\n  align-items: center;\n}\n@media (max-width: 56.25em) {\n  .BillPayment_services__iGS1G {\n    justify-content: flex-start;\n    flex-wrap: wrap;\n    grid-gap: 2rem;\n    gap: 2rem;\n  }\n}\n\n.BillPayment_service__3sYla {\n  text-align: center;\n  flex: 0 0 7rem;\n  padding: 1rem;\n  background: rgba(155, 180, 255, 0.1);\n  border-radius: 5px;\n  cursor: pointer;\n}\n@media (max-width: 37.5em) {\n  .BillPayment_service__3sYla {\n    flex: 0 0 20%;\n    margin: auto;\n    margin-top: 1.5rem;\n    height: 7rem;\n  }\n}\n\n.BillPayment_serviceLogo__3k3_W {\n  width: 3rem;\n  height: 3rem;\n  margin-bottom: 1rem;\n}\n@media (max-width: 37.5em) {\n  .BillPayment_serviceLogo__3k3_W {\n    margin-bottom: 0.5rem;\n  }\n}\n\n.BillPayment_serviceText__3ndne {\n  font-size: 1.2rem;\n  text-align: center;\n  color: #22215b;\n}", ""]);
// Exports
exports.locals = {
	"container": "BillPayment_container__1jLpU",
	"section": "BillPayment_section__2TDLw",
	"sectionHeading": "BillPayment_sectionHeading__ZAr8_",
	"card": "BillPayment_card__2Wo4_",
	"cardElectricity": "BillPayment_cardElectricity__d3ZBu",
	"services": "BillPayment_services__iGS1G",
	"service": "BillPayment_service__3sYla",
	"serviceLogo": "BillPayment_serviceLogo__3k3_W",
	"serviceText": "BillPayment_serviceText__3ndne"
};
module.exports = exports;
