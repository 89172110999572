// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".Button_button__3t9gw {\n  width: 100%;\n  height: 3.8rem;\n  background-color: #E4AA3D;\n  border: 0;\n  outline: 0;\n  cursor: pointer;\n  color: #fff;\n  font-size: 1.5rem;\n  border-radius: 0.9rem;\n  font-family: inherit;\n}\n.Button_button__3t9gw:disabled {\n  background-color: #e8e4df;\n  cursor: default;\n}\n.Button_button__3t9gw > svg {\n  width: 4rem;\n}", ""]);
// Exports
exports.locals = {
	"button": "Button_button__3t9gw"
};
module.exports = exports;
