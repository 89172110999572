// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".PersonalDetails_form__2Ksip {\n  padding: 3rem 2.5rem;\n  margin: 0 auto;\n  display: grid;\n  grid-template-columns: repeat(2, 1fr);\n  grid-gap: 3rem;\n}\n@media (max-width: 500px) {\n  .PersonalDetails_form__2Ksip {\n    grid-template-columns: none;\n    grid-gap: 2rem;\n    padding-top: 4rem;\n    padding-bottom: 1rem;\n  }\n}\n\n.PersonalDetails_formGroup__2hPk4 {\n  text-align: left;\n}\n\n.PersonalDetails_label__2aZva {\n  margin-bottom: 1rem;\n  color: #3d3d3d;\n  font-size: 1.5rem;\n}\n\n.PersonalDetails_input__kUmsj,\n.PersonalDetails_select__2s_RB {\n  margin-top: 0.8rem;\n  display: block;\n  border: 1px solid #c4c4c4;\n  padding: 0 1rem;\n  font-family: \"Avenir\";\n  color: #3d3d3d;\n  width: 100%;\n  height: 4rem;\n  border-radius: 5px;\n  font-size: 1.6rem;\n  background: #fff;\n}\n\n.PersonalDetails_errorText__3W4Ga {\n  font-size: 1.4rem;\n  color: rgb(226, 42, 42);\n  margin-top: 0.5rem;\n}\n\n@media (max-width: 20.62rem) {\n  .PersonalDetails_submit__2a7TJ {\n    margin-top: 3rem;\n  }\n}\n\n.PersonalDetails_button__oIa-3 {\n  color: #3d3d3d;\n}\n\n.PersonalDetails_submit__2a7TJ {\n  grid-column: 2/-1;\n  margin-top: 2rem;\n}\n@media (max-width: 20.62rem) {\n  .PersonalDetails_submit__2a7TJ {\n    margin-top: 3rem;\n  }\n}\n.PersonalDetails_submit__2a7TJ button {\n  width: 100%;\n  height: 4rem;\n  font-size: 1.6rem;\n  border-radius: 5px;\n  border: 0;\n  background-color: #E4AA3D;\n  color: #eee;\n  cursor: pointer;\n  outline: none;\n}", ""]);
// Exports
exports.locals = {
	"form": "PersonalDetails_form__2Ksip",
	"formGroup": "PersonalDetails_formGroup__2hPk4",
	"label": "PersonalDetails_label__2aZva",
	"input": "PersonalDetails_input__kUmsj",
	"select": "PersonalDetails_select__2s_RB",
	"errorText": "PersonalDetails_errorText__3W4Ga",
	"submit": "PersonalDetails_submit__2a7TJ",
	"button": "PersonalDetails_button__oIa-3"
};
module.exports = exports;
