// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".UploadPhoto_container__APy8c {\n  padding-top: 6rem;\n}\n.UploadPhoto_container__APy8c > .UploadPhoto_form__2MjPG {\n  height: 40.8rem;\n  width: 48.2rem;\n  margin: 0 auto;\n  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.1607843137);\n  padding: 3rem;\n  padding-top: 0;\n  display: flex;\n  flex-direction: column;\n  justify-content: center;\n  align-items: center;\n  flex-wrap: wrap;\n  background-color: #fff;\n}\n.UploadPhoto_container__APy8c > .UploadPhoto_form__2MjPG img {\n  width: 12rem;\n}\n.UploadPhoto_container__APy8c > .UploadPhoto_form__2MjPG label input {\n  width: 18.5rem;\n  margin-top: 3.5rem;\n  border-radius: 5px;\n  border: 1px solid #afafaf;\n  color: #707070;\n  font-family: inherit;\n}\n.UploadPhoto_container__APy8c > .UploadPhoto_form__2MjPG label input button {\n  background-color: green;\n}\n.UploadPhoto_container__APy8c > .UploadPhoto_form__2MjPG button {\n  font-family: \"Lato\", sans-serif;\n  width: 29.5rem;\n  margin: 0 auto;\n  margin-top: 5rem;\n  height: 3.6rem;\n  font-size: 1.6rem;\n  outline: 0;\n  border: 0;\n  height: 4.4rem;\n  border-radius: 1rem;\n  color: #fff;\n  box-shadow: 3px 3px 6px rgba(0, 0, 0, 0.1607843137);\n  background: transparent linear-gradient(90deg, #11998e 0%, #38ef7d 100%) 0% 0% no-repeat padding-box;\n}\n.UploadPhoto_container__APy8c > .UploadPhoto_form__2MjPG button:active {\n  transform: translateY(3%);\n  box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.1607843137);\n}", ""]);
// Exports
exports.locals = {
	"container": "UploadPhoto_container__APy8c",
	"form": "UploadPhoto_form__2MjPG"
};
module.exports = exports;
