// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".ExportToExcel_button__2XIU7 {\n  border: 0;\n  height: 3.5rem;\n  width: 11rem;\n  box-shadow: 0 0.2rem 0.3rem rgba(0, 0, 0, 0.1607843137);\n  color: #707087;\n  font-size: inherit;\n  font-family: inherit;\n  padding: 0 0.5rem;\n  border-radius: 5px;\n  background-color: #fff;\n  cursor: pointer;\n}\n.ExportToExcel_button__2XIU7 > img {\n  width: 2rem;\n  vertical-align: middle;\n  margin-right: 1rem;\n}", ""]);
// Exports
exports.locals = {
	"button": "ExportToExcel_button__2XIU7"
};
module.exports = exports;
