// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".terminals_container__1Gpn8 {\n  padding: 3rem 1rem;\n  background-color: #fff;\n}\n@media (max-width: 56.25em) {\n  .terminals_container__1Gpn8 {\n    padding: 1.5rem 0;\n  }\n}\n\n.terminals_menu__2-aAZ {\n  width: 100%;\n  display: flex;\n  flex-wrap: wrap;\n  justify-content: flex-start;\n  margin-bottom: 3rem;\n}\n@media (max-width: 56.25em) {\n  .terminals_menu__2-aAZ {\n    justify-content: flex-start;\n  }\n}\n\n.terminals_card__3WhbO {\n  width: 15rem;\n  height: 13rem;\n  padding: 1rem;\n  background: #ffffff;\n  box-shadow: 0px 2px 2px -2px rgba(0, 0, 0, 0.25);\n  border-radius: 5px;\n}\n.terminals_sectionHeading__3deB0 {\n  margin-bottom: 1.5rem;\n  font-size: 1.4rem;\n  font-family: \"Nunito\", sans-serif;\n  color: #22215b;\n}\n\n.terminals_services__38tGv {\n  display: flex;\n  justify-content: space-around;\n  align-items: center;\n}\n\n.terminals_service__2MJhp {\n  text-align: center;\n  width: 10rem;\n  padding: 1rem;\n  background: rgba(155, 180, 255, 0.1);\n  border-radius: 5px;\n  cursor: pointer;\n}\n\n.terminals_serviceLogo__2s6uO {\n  width: 3rem;\n  margin-bottom: 1rem;\n}\n\n.terminals_serviceText__2Qd-E {\n  font-size: 1.2rem;\n  text-align: center;\n  color: #22215b;\n}", ""]);
// Exports
exports.locals = {
	"container": "terminals_container__1Gpn8",
	"menu": "terminals_menu__2-aAZ",
	"card": "terminals_card__3WhbO",
	"sectionHeading": "terminals_sectionHeading__3deB0",
	"services": "terminals_services__38tGv",
	"service": "terminals_service__2MJhp",
	"serviceLogo": "terminals_serviceLogo__2s6uO",
	"serviceText": "terminals_serviceText__2Qd-E"
};
module.exports = exports;
