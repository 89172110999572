// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".FailedTransaction_container__3-z9P {\n  width: 30rem;\n  line-height: 2.3rem;\n  font-size: 1.44rem;\n  margin: 0 auto;\n  text-align: center;\n  position: relative;\n  top: 4rem;\n  height: 30rem;\n  width: 35rem;\n  background-color: #fff;\n  box-shadow: 0 0.2rem 0.3rem rgba(0, 0, 0, 0.1607843137);\n  padding: 2rem;\n}\n.FailedTransaction_container__3-z9P img {\n  width: 4rem;\n}\n.FailedTransaction_container__3-z9P p {\n  margin-top: 2rem;\n}\n.FailedTransaction_container__3-z9P div {\n  display: flex;\n  justify-content: space-around;\n  transform: translateY(9rem);\n  line-height: 27px;\n  font-size: 1.44rem;\n  margin-bottom: 3rem;\n}\n.FailedTransaction_container__3-z9P div a {\n  text-decoration: none;\n  color: #fff;\n  display: inline-block;\n  width: 12rem;\n  height: 3rem;\n  border-radius: 7px;\n  background-color: rgba(255, 6, 6, 0.74);\n}", ""]);
// Exports
exports.locals = {
	"container": "FailedTransaction_container__3-z9P"
};
module.exports = exports;
