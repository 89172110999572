// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".AirtimeData_container__3e3wL {\n  padding-top: 2rem;\n}\n\n.AirtimeData_section__17JeT {\n  margin-bottom: 2rem;\n}\n\n.AirtimeData_sectionHeading__1nJ_r {\n  margin-bottom: 1.5rem;\n  font-size: 1.4rem;\n  font-family: \"Nunito\", sans-serif;\n  color: #22215b;\n}\n\n.AirtimeData_card__2gKkL {\n  width: 38rem;\n  height: 13rem;\n  padding: 1rem;\n  background: #ffffff;\n  box-shadow: 0px 2px 2px -2px rgba(0, 0, 0, 0.25);\n  border-radius: 5px;\n}\n@media (max-width: 37.5em) {\n  .AirtimeData_card__2gKkL {\n    width: 100%;\n  }\n}\n\n.AirtimeData_services__B2cIj {\n  display: flex;\n  justify-content: space-around;\n  align-items: center;\n}\n\n.AirtimeData_service__3Pwpc {\n  text-align: center;\n  flex: 0 0 23%;\n  padding: 1rem;\n  background: rgba(155, 180, 255, 0.1);\n  border-radius: 5px;\n  cursor: pointer;\n}\n\n.AirtimeData_serviceLogo__2hPf_ {\n  width: 3rem;\n  margin-bottom: 1rem;\n}\n\n.AirtimeData_serviceText__1aV45 {\n  font-size: 1.2rem;\n  text-align: center;\n  color: #22215b;\n}", ""]);
// Exports
exports.locals = {
	"container": "AirtimeData_container__3e3wL",
	"section": "AirtimeData_section__17JeT",
	"sectionHeading": "AirtimeData_sectionHeading__1nJ_r",
	"card": "AirtimeData_card__2gKkL",
	"services": "AirtimeData_services__B2cIj",
	"service": "AirtimeData_service__3Pwpc",
	"serviceLogo": "AirtimeData_serviceLogo__2hPf_",
	"serviceText": "AirtimeData_serviceText__1aV45"
};
module.exports = exports;
