// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".FundWalletStatus_container__294ao {\n  padding-top: 4rem;\n}\n.FundWalletStatus_container__294ao > .FundWalletStatus_sectionContainer__2QZfq {\n  height: 34.2rem;\n  width: 36.6rem;\n  margin: 0 auto;\n  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.1607843137);\n  padding-bottom: 3rem;\n  background-color: #fff;\n  color: #707070;\n  font-size: 1.45rem;\n  display: flex;\n  justify-content: space-between;\n  flex-direction: column;\n}\n.FundWalletStatus_container__294ao > .FundWalletStatus_sectionContainer__2QZfq .FundWalletStatus_imageContainer__j9ROz {\n  display: flex;\n  flex-direction: column;\n  justify-content: center;\n  align-items: center;\n  flex: 0 0 5%;\n  margin-bottom: 1rem;\n}\n.FundWalletStatus_container__294ao > .FundWalletStatus_sectionContainer__2QZfq .FundWalletStatus_imageContainer__j9ROz img {\n  position: relative;\n  bottom: 2rem;\n  width: 4rem;\n}\n.FundWalletStatus_container__294ao > .FundWalletStatus_sectionContainer__2QZfq .FundWalletStatus_imageContainer__j9ROz p {\n  font-size: 1.4rem;\n}\n.FundWalletStatus_container__294ao > .FundWalletStatus_sectionContainer__2QZfq .FundWalletStatus_contentContainer__UcRzs {\n  flex: 1 1;\n  margin-top: 2rem;\n}\n.FundWalletStatus_container__294ao > .FundWalletStatus_sectionContainer__2QZfq .FundWalletStatus_contentContainer__UcRzs .FundWalletStatus_content__1gX_M {\n  width: 85%;\n  line-height: 25px;\n  font-size: 1.4rem;\n  margin: 2rem auto 0 auto;\n  text-align: center;\n}\n.FundWalletStatus_container__294ao > .FundWalletStatus_sectionContainer__2QZfq .FundWalletStatus_contentContainer__UcRzs .FundWalletStatus_content__1gX_M div {\n  position: relative;\n  top: 12.2rem;\n  right: 14rem;\n}\n.FundWalletStatus_container__294ao > .FundWalletStatus_sectionContainer__2QZfq .FundWalletStatus_contentContainer__UcRzs .FundWalletStatus_content__1gX_M div a {\n  text-decoration: none;\n  color: #707070;\n}", ""]);
// Exports
exports.locals = {
	"container": "FundWalletStatus_container__294ao",
	"sectionContainer": "FundWalletStatus_sectionContainer__2QZfq",
	"imageContainer": "FundWalletStatus_imageContainer__j9ROz",
	"contentContainer": "FundWalletStatus_contentContainer__UcRzs",
	"content": "FundWalletStatus_content__1gX_M"
};
module.exports = exports;
