// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".Main_main__3ZOM7 {\n  flex: 1 1;\n  min-height: 100vh;\n  overflow: hidden;\n  position: relative;\n  max-width: 100vw;\n  display: flex;\n  flex-direction: column;\n}\n.Main_main__3ZOM7 .Main_contentContainer__35s67 {\n  position: relative;\n  left: 0.3rem;\n  width: 100%;\n  flex: 1 1;\n  min-height: calc(100vh - 7rem);\n  background-color: #f2f4f5;\n  padding: 3rem 7rem;\n  background: #fff;\n  border-top-left-radius: 1.5rem;\n  padding: 2rem 3rem;\n  box-shadow: -4px 4px 12px rgba(10, 46, 101, 0.05);\n  max-width: 100%;\n  overflow: hidden;\n}\n@media (max-width: 37.5em) {\n  .Main_main__3ZOM7 .Main_contentContainer__35s67 {\n    padding: 0.5rem;\n    min-height: calc(100vh - 5rem);\n    overflow: hidden;\n  }\n}\n\n.Main_content__z9Cmm {\n  overflow: auto;\n  width: 100%;\n  height: 100%;\n  padding: 0 2rem;\n  position: relative;\n}\n@media (max-width: 56.25em) {\n  .Main_content__z9Cmm {\n    padding: 0 0.5rem;\n  }\n}\n\n.Main_maxHeight__260Wt {\n  max-height: calc(100vh - 7rem);\n  overflow: hidden;\n}\n@media (max-width: 37.5em) {\n  .Main_maxHeight__260Wt {\n    max-height: 100vh;\n  }\n}\n\n.Main_contentMain__2p9oL {\n  overflow: auto;\n}\n@keyframes Main_AnimationName__2d6Pw {\n  0% {\n    background-position: 0% 50%;\n  }\n  50% {\n    background-position: 100% 50%;\n  }\n  100% {\n    background-position: 0% 50%;\n  }\n}\n.Main_back__2a_GY {\n  display: inline-block;\n  margin: 2rem 0;\n  font-size: 1.6rem;\n  cursor: pointer;\n}\n.Main_back__2a_GY > img {\n  width: 2rem;\n  vertical-align: middle;\n  margin-right: 0.5rem;\n}", ""]);
// Exports
exports.locals = {
	"main": "Main_main__3ZOM7",
	"contentContainer": "Main_contentContainer__35s67",
	"content": "Main_content__z9Cmm",
	"maxHeight": "Main_maxHeight__260Wt",
	"contentMain": "Main_contentMain__2p9oL",
	"back": "Main_back__2a_GY",
	"AnimationName": "Main_AnimationName__2d6Pw"
};
module.exports = exports;
