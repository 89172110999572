// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".FundWallet_container__1_mP1 {\n  padding-top: 1.5rem;\n}", ""]);
// Exports
exports.locals = {
	"container": "FundWallet_container__1_mP1"
};
module.exports = exports;
