// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".BusinessDetails_form__s6gTY {\n  padding: 3rem 2.5rem;\n  margin: 0 auto;\n  display: grid;\n  grid-template-columns: repeat(2, 1fr);\n  grid-gap: 3rem;\n}\n@media (max-width: 500px) {\n  .BusinessDetails_form__s6gTY {\n    grid-template-columns: none;\n    grid-gap: 2rem;\n    padding-top: 4rem;\n    padding-bottom: 1rem;\n  }\n}\n\n.BusinessDetails_formGroup__2LvX8 {\n  text-align: left;\n}\n\n.BusinessDetails_label__1ZQAS {\n  margin-bottom: 1rem;\n  color: #3d3d3d;\n  font-size: 1.5rem;\n}\n\n.BusinessDetails_input__24Cxd,\n.BusinessDetails_select__399_L {\n  margin-top: 0.8rem;\n  display: block;\n  border: 1px solid #c4c4c4;\n  padding: 0 1rem;\n  font-family: \"Avenir\";\n  color: #3d3d3d;\n  width: 100%;\n  height: 4rem;\n  border-radius: 5px;\n  font-size: 1.6rem;\n  background: #fff;\n}\n\n.BusinessDetails_errorText__uJoRd {\n  font-size: 1.4rem;\n  color: rgb(226, 42, 42);\n  margin-top: 0.5rem;\n}\n\n@media (max-width: 20.62rem) {\n  .BusinessDetails_submit__UVgW- {\n    margin-top: 3rem;\n  }\n}\n\n.BusinessDetails_button__zopBv {\n  color: #3d3d3d;\n}\n\n.BusinessDetails_submit__UVgW- {\n  grid-column: 1/-1;\n  margin-top: 2rem;\n  display: grid;\n  grid-template-columns: repeat(2, 1fr);\n  grid-gap: 3rem;\n}\n@media (max-width: 20.62rem) {\n  .BusinessDetails_submit__UVgW- {\n    margin-top: 3rem;\n  }\n}\n.BusinessDetails_submit__UVgW- .BusinessDetails_button__zopBv {\n  flex: 0 0 44%;\n  height: 4rem;\n  border-radius: 5px;\n  border: 0;\n  background-color: #E4AA3D;\n  color: #eee;\n  cursor: pointer;\n  outline: none;\n  font-size: 1.6rem;\n}\n.BusinessDetails_submit__UVgW- .BusinessDetails_button__zopBv svg {\n  width: 3rem;\n  position: relative;\n  top: 2px;\n}\n.BusinessDetails_submit__UVgW- .BusinessDetails_back__D9XXU {\n  background-color: #fff;\n  color: #E4AA3D;\n  border: 1px solid #E4AA3D !important;\n}", ""]);
// Exports
exports.locals = {
	"form": "BusinessDetails_form__s6gTY",
	"formGroup": "BusinessDetails_formGroup__2LvX8",
	"label": "BusinessDetails_label__1ZQAS",
	"input": "BusinessDetails_input__24Cxd",
	"select": "BusinessDetails_select__399_L",
	"errorText": "BusinessDetails_errorText__uJoRd",
	"submit": "BusinessDetails_submit__UVgW-",
	"button": "BusinessDetails_button__zopBv",
	"back": "BusinessDetails_back__D9XXU"
};
module.exports = exports;
