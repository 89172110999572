// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".BuyInsurance_container__2nU7n {\n  width: 100%;\n}", ""]);
// Exports
exports.locals = {
	"container": "BuyInsurance_container__2nU7n"
};
module.exports = exports;
